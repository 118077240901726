import { MutationResult } from '@apollo/client';
import { useContext } from 'react';
import { AddProductToCartMutation } from '../../__generated__/graphql-client-types';
import { FEATURE_FLAGS } from '../../constants/general';
import { AddToCartContext } from '../../contexts/add-to-cart/add-to-cart.context';
import { ProductFamilyContext } from '../../contexts/product-family/product-family.context';
import { VariantContext } from '../../contexts/variant/variant.context';
import { defaultAddToCartAction, getButtonType } from '../../helpers/product-helper/add-to-cart-button/add-to-cart-button.helper';
import { useSiteViewPreference } from '../apollo/employee/employee.hooks';
import { useFeature } from '../features/features.hooks';
import { useNotification } from '../notification/notification.hooks';
import { useAddToCartAction } from './add-to-cart.hooks';

type UseAddToCartButtonParams = {
	pageName: string;
	onClick?: (addToCart: Function) => void;
	productUniqueId?: number;
	quantity?: number;
};
/**
 * Preps the logic and type for the Add to Cart button
 */
export const useAddToCartButton = ({
	pageName,
	onClick,
	productUniqueId,
	quantity = 1
}: UseAddToCartButtonParams): {
	buttonType: string;
	handleOnClick: () => void;
	addToCartActionStatus: MutationResult<AddProductToCartMutation>;
} => {
	const { productFamily } = useContext(ProductFamilyContext);
	const { currentVariant } = useContext(VariantContext);
	const { showAsEmployee } = useSiteViewPreference();
	const { selection } = useContext(AddToCartContext);
	const cartQuantity = selection?.quantity || quantity;
	const { notifyDanger, notifySuccess } = useNotification();
	const { addToCart, status } = useAddToCartAction();
	const isManagedPurchaseDisplayActive = useFeature(FEATURE_FLAGS.MANAGED_PURCHASE_DISPLAY);
	const variantId = productUniqueId ? productUniqueId : currentVariant.id;

	const buttonType = getButtonType(currentVariant, showAsEmployee, isManagedPurchaseDisplayActive);
	const addToCartAction = () =>
		defaultAddToCartAction(addToCart, pageName, cartQuantity, variantId, productFamily, notifyDanger, notifySuccess);

	const handleOnClick = () => (onClick ? onClick(addToCartAction) : addToCartAction());

	return { buttonType, handleOnClick, addToCartActionStatus: status };
};
