import gql from 'graphql-tag';

export const TRACK_SEARCH_RESULT_CLICK = gql`
	mutation TrackSearchResultClick($input: TrackSearchResultClickInput!) {
		trackSearchResultClick(input: $input)
	}
`;

export const TRACK_SEARCH_ADD_TO_CART = gql`
	mutation TrackSearchAddToCart($input: TrackSearchAddToCartInput!) {
		trackSearchAddToCart(input: $input)
	}
`;
