import gql from 'graphql-tag';

/**
 * Used for getting Rebates from Eco Rebates
 */
export const PRODUCT_ECO_REBATES = gql`
	query EcoRebatesQuery($variantIds: [Int!]!, $zipCode: String!) {
		currentEcoRebates(variantIds: $variantIds, zipCode: $zipCode) {
			id
			title
			description
			details
			zipCode
			buyBefore
			claimBy
			phoneNumber
			submitUrl
			mailInUrl
			sku
		}
	}
`;
